<template>
    <div class="quick-search" v-if="typeof quickSearch !== 'undefined'">
        <mercur-input v-model="quickSearchLocal">
            <template #prefix><i class="fas fa-search"></i></template>
        </mercur-input>
    </div>
</template>

<script>
export default {
    name: 'QuickSearch',
    props: ['quickSearch'],
    computed: {
        quickSearchLocal: {
            get () {
                return this.quickSearch
            },
            set (value) {
                this.$emit('update:quickSearch', value)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
    .quick-search {
        max-width: 250px;
    }
</style>
